import axios from "axios";
import sysConfig from '@/config'
import { getToken } from '@/util/auth';
import { Toast } from 'vant';
axios.defaults.timeout = 300000;
axios.interceptors.request.use((config) => {
    const meta = (config.meta || {});
    const isToken = meta.isToken === false;
    config.headers['Authorization'] = sysConfig.AUTHORIZATION;
    if (getToken() && !isToken) {
        config.headers[sysConfig.TOKEN_HEADER] = 'bearer ' + getToken()
    }
    return config
}, (err) => {
    return Promise.reject(err)
})
axios.interceptors.response.use((result) => {
    const status = result.data.code || result.status;
    const message = result.data.msg || result.data.error_description || '未知错误';
    if (status !== 200) {
        Toast.fail(message);
        setTimeout(() => {
            Toast.clear()
        }, 2000);
        return Promise.reject(new Error(message))
    }
    return result.data
}, (err) => {
    Toast.clear()
    return Promise.reject(new Error(err))
})

export default axios