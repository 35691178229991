// let PC_URL = "http://192.168.5.16:1889" //电脑端访问基础路径 本地
let PC_URL = "http://www.yckxt.com" //电脑端访问基础路径 线上
export const config = {
  // 微信相关
  // APP_ID: 'wx389c54e2cb5e8934', //公众号appId 
  // CALLBACK_URL: 'http://local.mj.ink/', //微信授权回调地址 本地 
  CALLBACK_URL: 'http://gzh.yckxt.com', //微信授权回调地址 线上

  // 系统登录相关
  GRANT_TYPE: 'mjkj',
  SCOPE: 'all',
  LOGIN_TYPE: 'wxmp',
  AUTHORIZATION: 'Basic bWprajptamtqX3NlY3JldA==', //取消
  TOKEN_HEADER: 'Blade-Auth', //token key
  TOKENTIME: 3000, //token检查时间
  APIREQUESTHEAD: 'mjkj-bladex', //公共后端请求头

  //电脑端相关
  PC_FORM_VISIT_URL: `${PC_URL}/#/external/formView`,//电脑端表单访问路径

  //流程相关
  PC_FLOW_FORM_VISIT_URL: `${PC_URL}/#/external/flow/start`,//电脑端表单流程访问页面
  // FLOW_ID_DATA: { //流程id
  //   //本地
  //   FLOW_GZFK: {//故障反馈流程id
  //     id: 'zhdt_flow_gzfk:2:b64ce252-8e60-11ed-b7ba-0ae0afb368ca',
  //     key: 'zhdt_flow_gzfk'
  //   },
  //   FLOW_WTFK: {//问题反馈流程id
  //     id: 'zhdt_flow_jffk:1:3fdf13c7-8be1-11ed-bff5-0ae0afb368ca',
  //     key: 'zhdt_flow_jffk'
  //   },
  //   FLOW_BYSQ: {//保养申请流程id
  //     id: 'zhdt_flow_bysq:2:014fe777-8e68-11ed-b7ba-0ae0afb368ca',
  //     key: 'zhdt_flow_bysq'
  //   },

  //线上
  FLOW_ID_DATA: { //流程id
    FLOW_GZFK: {//故障反馈流程id
      id: 'zhdt_flow_gzfk:2:b64ce252-8e60-11ed-b7ba-0ae0afb368ca',
      key: 'zhdt_flow_gzfk'
    },
    FLOW_WTFK: {//问题反馈流程id
      id: 'zhdt_flow_jffk:7:97dc0092-8e32-11ed-b7ba-0ae0afb368ca',
      key: 'zhdt_flow_jffk'
    },
    FLOW_BYSQ: {//保养申请流程id
      id: 'zhdt_flow_bysq:2:014fe777-8e68-11ed-b7ba-0ae0afb368ca',
      key: 'zhdt_flow_bysq'
    },
  },
}
export default config