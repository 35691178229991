<template>
  <div>
    <div class="top-home-box" :style="styleObj" :class="[{'top-home-title':title},...className]">
      <div class="home-title">{{title}}</div>
      <div class="top-home" @click="$router.back()">
        <van-icon name="arrow-left" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '测试',
    },
    styleObj: {
      type: Object,
      default: () => {},
    },
    className: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="less" scoped>
.top-home-box {
  position: fixed;
  left: 0;
  top: 0;
  height: 39px;
  z-index: 999;
  color: #fff;
}
.top-home {
  position: absolute;
  left: 20px;
  top: 20px;
  transform: translateY(-50%);
  font-size: 16px;
}

.top-home-title {
  width: 100%;
  box-sizing: border-box;
  background-color: #23a9fe;
  font-size: 14px;
  .home-title {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.top-white {
  box-sizing: border-box;
  background-color: #fff;
  color: #232323;
  border-bottom: 1px solid #f1f1f1;
}
</style>