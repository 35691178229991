<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { validatenull } from '@/util/validate'
import { getStore } from '@/util/store.js'
import { calcDate } from '@/util/date.js'
import config from '@/config'
export default {
  data() {
    return {
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: '',
    }
  },
  mounted() {
    this.refreshToken()
  },
  computed: {},
  methods: {
    // 定时检测token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token =
          getStore({
            name: 'token',
            debug: true,
          }) || {}
        const date = calcDate(token.datetime, new Date().getTime())
        if (validatenull(date)) return
        if (date.seconds >= config.TOKENTIME && !this.refreshLock) {
          this.refreshLock = true
          this.$store
            .dispatch('refreshToken')
            .then(() => {
              this.refreshLock = false
            })
            .catch(() => {
              this.refreshLock = false
            })
        }
      }, 10000)
    },
  },
}
</script>
<style lang="less">
#app {
  text-align: center;
  width: 100%;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.error {
  color: red !important;
}
.success {
  color: #008000 !important;
}
.top-padding {
  padding-top: 39px;
  box-sizing: border-box;
}
</style>
