<template>
  <div class="contents">
    <div class="title_Pic Integral">
      <div class="Integral-one">
        <div class="Integral-top-oimg">
          <!-- <img src="../assets/home/IndexAddress.png" alt width="18px" />
          <span>123</span> -->
        </div>
        <div>
          <span>您好,{{wxLoginInfo.nickname}}</span>
          <img
            src="../assets/home/set.png"
            alt
            width="22px"
            @click="jumpPindex"
          />
        </div>
      </div>
    </div>
    <div class="conInfo">
      <div class="Index_UserInfo UserInfo">
        <div class="title">
          <span style="font-size: 19px">缴费用户</span>
          <div>
            <img
              src="../assets/home/point.png"
              alt
              width="24px"
              @click="jumpFindTenant"
            />
          </div>
        </div>
        <div
          class="title-dd"
          @click="showPopup"
        >
          <div class="tb">
            <div style="width:50px;height:50px;padding:8px;background-color:#008dff;border-radius:50px;box-sizing:border-box">
              <img
                src="../assets/home/pay.png"
                alt
              />
            </div>
            <div class="user-info-box ellipsis">
              <div class="ellipsis">{{currUserData.name}}</div>
              <div class="ellipsis">{{currUserData.tenant_name}}</div>
            </div>
            <div class="nextarrow">
              <img
                src="../assets/home/nextarrow.png"
                alt
                width="10px"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="index_content contBg">
        <ul class="ContentBg">
          <div class="content-item">
            <li @click="jumpTradingRecords">
              <img
                src="../assets/home/index_icon1.png"
                alt
              />
              <span>交易记录</span>
            </li>
            <li @click="jumpPayFee">
              <img
                src="../assets/home/index_icon2.png"
                alt
                class="img-two"
              />
              <span>缴纳费用</span>
            </li>
            <li @click="jumpBillQuery">
              <img
                src="../assets/home/index_icon3.png"
                alt
              />
              <span>账单查询</span>
            </li>
          </div>
          <!-- <div class="content-item">
            <li @click="jumpServicePhoneIndex">
              <img
                src="../assets/home/index_icon4.png"
                alt
              />
              <span>营业网点</span>
            </li>
            <li @click="$router.push('/busTabs/zzcb')">
              <img
                src="../assets/home/index_icon5.png"
                alt
              />
              <span>自助抄表</span>
            </li>
            <li @click="$router.push('/BusTabs/wtfk')">
              <img
                src="../assets/home/index_icon6.png"
                alt
              />
              <span>问题反馈</span>
            </li>
          </div> -->
        </ul>
        <div class="none-area"></div>
        <div>
          <div
            class="titleBg"
            @click="jumpBusIndex('wbxx')"
          >
            <span>维保信息</span>
            <div>
              <span class="span2">更多</span>
              <van-icon
                name="arrow"
                color="rgb(182, 182, 182)"
              />
            </div>
          </div>
          <div class="ywbl-box">
            <div
              class="ywbl-item"
              @click="$router.push({path:`/maintainceInfo`,query:{active:0}})"
            >
              <div class="item-title">合同信息</div>
              <div class="wbxx-item">
                <img src="../assets/home/info.png" />
              </div>
            </div>
            <div
              class="ywbl-item"
              @click="$router.push({path:`/maintainceInfo`,query:{active:1}})"
            >
              <div class="item-title">整改报价</div>
              <div class="wbxx-item">
                <img src="../assets/home/info.png" />
              </div>
            </div>
            <div
              class="ywbl-item"
              @click="$router.push({path:`/maintainceInfo`,query:{active:2}})"
            >
              <div class="item-title">维保异常</div>
              <div class="wbxx-item">
                <img src="../assets/home/info.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="none-area"></div>
        <div>
          <div
            class="titleBg"
            @click="jumpBusIndex('ywbl')"
          >
            <span>业务办理</span>
            <div>
              <span class="span2">更多</span>
              <van-icon
                name="arrow"
                color="rgb(182, 182, 182)"
              />
            </div>
          </div>
          <div class="ywbl-box">
            <div
              class="ywbl-item"
              @click="$router.push('/busTabs/gzfk')"
            >
              <div class="item-title">故障反馈</div>
              <img src="../assets/MoreMenuManager/folder.5a9b6de5.png" />
            </div>
            <div
              class="ywbl-item"
              @click="$router.push('/busTabs/wtfk')"
            >
              <div class="item-title">问题反馈</div>

              <img src="../assets/MoreMenuManager/folder.5a9b6de5.png" />
            </div>
            <div
              class="ywbl-item"
              @click="$router.push('/busTabs/bysq')"
            >
              <div class="item-title">保养申请</div>
              <img src="../assets/MoreMenuManager/folder.5a9b6de5.png" />
            </div>
            <div
              class="ywbl-item"
              @click="$router.push('/confirm/byqr')"
            >
              <div class="item-title">保养确认</div>
              <img src="../assets/MoreMenuManager/folder.5a9b6de5.png" />
            </div>
          </div>
        </div>
        <div class="none-area"></div>
        <div>
          <div
            class="titleBg"
            @click="jumpNoticeIndex('xtgg')"
          >
            <span>系统公告</span>
            <div>
              <span class="span2">更多</span>
              <van-icon
                name="arrow"
                color="rgb(182, 182, 182)"
              />
            </div>
          </div>
          <div
            v-for="(item,index) in sysList"
            :key="index"
            @click="$router.push({path:'/noticeDetails',query:{id:item.id,type:'xtgg'}})"
            class="xtgg-box"
          >
            <div class="xtgg-cotent ellipsis">
              <div
                style="vertical-align: middle"
                class="info-content"
                v-html="item.nr"
              ></div>
              <div class="xtgg-time">
                {{item.gglx}} | {{item.create_time}}
              </div>
            </div>
          </div>
          <div
            class="list-empty"
            v-if="sysList.length<=0"
          >暂无系统公告</div>
        </div>
        <div class="none-area"></div>
        <div>
          <div
            class="titleBg"
            @click="jumpNoticeIndex('tsxx')"
          >
            <span>推送消息</span>
            <div>
              <span class="span2">更多</span>
              <van-icon
                name="arrow"
                color="rgb(182, 182, 182)"
              />
            </div>
          </div>
          <!-- @click="$router.push(`/noticeDetails/${item.id}`)" -->
          <div
            v-for="(item,index) in smsList"
            :key="index"
            @click="$router.push({path:'/noticeDetails',query:{id:item.id,type:'tsxx'}})"
            class="xtgg-box"
          >
            <div class="xtgg-cotent ellipsis">
              <div
                style="vertical-align: middle"
                class="info-content"
                v-html="item.xxnr"
              ></div>
              <div class="xtgg-time">
                {{item.xxlx}} | {{item.fssj}}
              </div>
            </div>
          </div>
          <div
            class="list-empty"
            v-if="smsList.length<=0"
          >暂无消息</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAnnouncListApi, getMessageListApi } from '@/reuqest/api.js'
import { mapGetters, mapMutations } from 'vuex'
export default {
  data () {
    return {
      username: '',
      password: '',
      sysList: [],
      smsList: [],
    }
  },
  computed: {
    ...mapGetters(['wxLoginInfo', 'bindList', 'tenantId']),
    currUserData () {
      let data = {}
      this.bindList.forEach((item) => {
        if (item.flag) {
          data = item
        }
      })
      return data
    },
  },
  mounted () {
    if (this.bindList.length <= 0) {
      this.SET_IS_CUT(true)
    }
    this.$store.dispatch('SystemLogin').then(async () => {
      //获取公告
      getAnnouncListApi({ ismore: 0, tenantId: this.tenantId }).then((res) => {
        this.sysList = res.data
      })
      //获取消息
      getMessageListApi({ ismore: 0, tenantId: this.tenantId }).then((res) => {
        this.smsList = res.data
      })
    })
  },
  methods: {
    ...mapMutations(['SET_IS_CUT']),
    jumpShareIndex () {
      this.$router.push('/shareIndex')
    },
    showPopup () {
      this.$router.push({ path: '/BandingAccount' })
      // this.show = true
    },
    jumpFindTenant () {
      this.$router.push('/FindTenant')
    },
    jumpPindex () {
      this.$router.push('/index')
    },
    jumpNoticeIndex (type) {
      this.$router.push({
        path: '/noticeIndex',
        query: { type }
      })
    },
    jumpBusIndex (type) {
      this.$router.push({
        path: '/busIndex',
        query: { type }
      })
    },
    jumpServicePhoneIndex () {
      this.$router.push('/ServicePhoneIndex')
    },
    jumpBillQuery () {
      this.$router.push('/BillQuery')
    },
    jumpPayFee () {
      this.$router.push('/PayFee')
    },

    jumpTradingRecords () {
      this.$router.push('/TradingRecords')
    },

    jumpTradingRecords () {
      this.$router.push('/TradingRecords')
    },
  },
}
</script>
 
<style lang="less" scoped>
.none-area {
  background-color: rgb(245, 245, 245);
  height: 8px;
}
.title-dd {
  margin: 0px 10px 20px 10px;
  border-top: 1px solid rgb(241, 241, 241);
}
.contBg {
  width: 100%;
  border-radius: 0px;
}
.contents .Integral {
  height: 160px;
}
.conInfo .UserInfo {
  box-shadow: 0px 1px 5px #9ec6fc;
  min-height: 130px;
  .van-popup {
    width: 70%;
    display: flex;
    justify-content: center;
    padding-top: 10% !important;
    padding: 4%;
    .van-form {
      width: 100% !important;
      .van-field {
        margin: 15px 0;
      }
      .van-button {
        margin-top: 40px;
      }
    }
  }
}
.title_Pic {
  background: url('../assets/home/topnewbg.jpg') no-repeat;
  background-size: 100% 100%;
  height: 100px;
  .Integral-one {
    display: flex;
    justify-content: space-between;

    align-items: center;

    padding: 18px;
    span {
      color: #fff;
      margin-right: 10px;
      vertical-align: middle;
    }
    img {
      vertical-align: middle;
    }
  }
  .Integral-two {
    display: flex;
    justify-content: center;
    div {
      width: 33.3%;
      span {
        color: #fff;
        font-size: 14px;
        vertical-align: middle;
        padding: 3px;
      }
      img {
        padding: 2px;
        vertical-align: middle;
      }
    }

    .Integral-two-two {
      margin: 15px;
    }
  }
}
.Index_UserInfo {
  width: 94%;
  background: #fff;
  margin: 0 auto;
  margin-top: -90px;
  border-radius: 15px;
  padding-bottom: 22px;
  position: relative;
  z-index: 1;

  .title {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 15px 10px;
  }

  .tb {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 18px;
    .user-info-box {
      font-size: 18px;
      flex: 1;
      text-align: left;
      padding-left: 10px;
      color: #333;
      div:nth-child(2) {
        padding-top: 4px;
      }
    }
  }
}
.index_content {
  width: 100%;
  background: #fff;
  margin: 0 auto;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 0 10px;
  overflow: hidden;
  margin-bottom: 40px;
  .ContentBg {
    background: url('../assets/home/index_iconbg1.png') no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    .content-item {
      display: flex;
      width: 100%;
      height: 100px;
      box-sizing: border-box;
      li {
        flex: 1;
        span {
          display: block;
          font-size: 14px;
        }
        img {
          width: 35px;
        }
        .img-two {
          width: 65px;
        }
      }
      &:nth-child(1) {
        padding-top: 20px;
      }
      &:nth-child(2) {
        padding-top: 35px;
      }
    }
    /* li:nth-child(3) {
      margin-bottom: 80px;
    }
    li:nth-child(1) {
      margin-top: -80px;
    }
    li:nth-child(2) {
      margin-top: -80px;
    }
    li:nth-child(4) {
      margin-top: -50px;
    }
    li:nth-child(5) {
      margin-top: -50px;
    }
    li:nth-child(6) {
      margin-top: -50px;
    }
     */
  }
  .titleBg {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 18px;
      color: rgb(0, 0, 0);
    }
    .span2 {
      color: rgb(102, 102, 102);
      font-size: 14px;
    }
  }
}
.b-pic {
  background: url('../assets/MoreMenuManager/personalCenterImg.jpg') no-repeat;

  background: url('../assets/MoreMenuManager/personalCenterImg.jpg') no-repeat;

  box-sizing: 100% 100%;
  height: 17vh;

  .b-pic-img {
    position: relative;
  }
  .b-num {
    position: absolute;
    color: #fff;
    font-size: 25px;
    left: 44%;
    top: 13%;
  }
}
.van-button--normal {
  padding: 0 40%;
  font-size: 10px;
}
.van-button--info {
  color: #fff;
  background-color: rgb(50, 153, 240);
  border: 1px solid rgb(50, 153, 240);
}

.titleBg-timg {
  display: flex;
  justify-content: space-between;
}
.list-empty {
  padding-bottom: 15px;
  font-size: 14px;
  color: #999;
}
.ywbl-box {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
  .ywbl-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    border: 1px soild #fff;
    border-radius: 5px;
    background-color: #f0f4f9;
    height: 100px;
    text-align: center;
    margin-left: 10px;
    padding: 10px 0;
    &:nth-child(1) {
      margin-left: 0px;
    }
    .item-title {
      padding: 10px 0;
      font-size: 16px;
    }
    .wbxx-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      background-color: #00a9ff;
      border-radius: 23px;
      img {
        width: 20px;
      }
    }
    img {
      width: 45px;
    }
  }
}
.xtgg-box {
  padding: 15px 0;
  border-top: 1px solid rgb(245, 245, 245);
  display: flex;
  align-items: center;
  .xtgg-cotent {
    flex: 1;
    padding-left: 10px;
    text-align: left;
    font-size: 16px;
  }
  .xtgg-time {
    font-size: 14px;
    color: #999;
    padding-top: 10px;
  }
}
.ellipsis {
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.info-content {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/deep/.info-content p {
  margin: 0 !important;
}
</style>