import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CbHome from '../views/CbHome.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      isAuth: false,
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/cbhome',
    name: 'CbHome',
    component: CbHome
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../components/Login/login.vue')
  }
  ,
  {
    path: '/personalCenter',
    name: 'PersonalCenter',
    component: () => import(/* webpackChunkName: "personalCenter" */ '../components/Ipad/PersonalCenter/PersonalCenter.vue')
  },
  {

    path: '/index',
    name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Index" */ '../components/Ipad/PersonalCenter/index.vue')

  },
  {
    // 子路由的path,要么不加斜杠,要么把前面的路径也写进去  popup    /home/popup
    path: '/BandingAccount',
    component: () => import(/* webpackChunkName: "BandingAccount" */ '../components/Ipad/PersonalCenter/BandingAccount.vue'),
    meta: {
      isAuth: false,
    }
  },
  {
    path: '/noticeIndex',
    name: 'NoticeIndex',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "noticeIndex" */ '../components/Notice/NoticeIndex.vue')
  }
  ,
  {
    path: '/noticeDetails',
    name: 'NoticeDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "noticeDetails" */ '../components/Notice/NoticeDetails.vue')
  },
  {
    path: '/pointsCenter',
    name: 'PointsCenter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PointsCenter" */ '../components/Ipad/PersonalCenter/PersonalCenter.vue')
  }
  ,
  {
    path: '/shareIndex',
    name: 'ShareIndex',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShareIndex" */ '../components/Ipad/PersonalCenter/ShareIndex.vue')
  }
  ,
  {
    path: '/busIndex',
    name: 'BusIndex',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "busIndex" */ '../components/MoreMenuManager/MoreMenu/BusIndex.vue')
  }
  ,
  {
    path: '/busTabs/:code',
    name: 'BusTabs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "busTabs" */ '../components/MoreMenuManager/BusTabs.vue')
  }
  ,
  {
    path: '/confirm/:code',
    name: 'confirm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "busTabs" */ '../components/MoreMenuManager/confirm.vue')
  },
  {
    path: '/ServicePhoneIndex',
    name: 'ServicePhoneIndex',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "busIndex" */ '../components/Notice/ServicePhoneIndex.vue')
  }
  ,
  {
    path: '/BillQuery',
    name: 'BillQuery',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "BillQuery" */ '../components/CostManager/BillQuery/BillQuery.vue')
  }
  ,
  {
    path: '/MayInvoice',
    name: 'MayInvoice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "MayInvoice" */ '../components/CostManager/BillQuery/MayInvoice.vue')
  }
  ,
  {
    path: '/MyInvoice',
    name: 'MyInvoice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "MayInvoice" */ '../components/CostManager/BillQuery/MyInvoice.vue')
  }
  ,
  {
    path: '/InvoiceDetails',
    name: 'InvoiceDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "InvoiceDetails" */ '../components/CostManager/BillQuery/InvoiceDetails.vue')
  }
  ,
  {
    path: '/PayFee',
    name: 'PayFee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PayFee" */ '../components/CostManager/PayFee/PayFee.vue')
  }
  ,
  {
    path: '/zfbPay/:goto',
    name: 'zfbPay',
    meta: {
      isAuth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PayFee" */ '../components/CostManager/PayFee/zfbPay.vue')
  }
  ,

  {
    path: '/Agricultural',
    name: 'Agricultural',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Agricultural" */ '../components/CostManager/PayFee/Agricultural.vue')
  }
  ,
  {
    path: '/DisparkBank',
    name: 'DisparkBank',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "DisparkBank" */ '../components/CostManager/PayFee/DisparkBank.vue')
  },
  {
    path: '/FindTenant',
    name: 'FindTenant',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "FindTenant" */ '../components/Ipad/PersonalCenter/FindTenant/FindTenant.vue'),
    meta: {
      isAuth: false,
    }
  }
  ,
  {
    path: '/TradingRecords',
    name: 'TradingRecords',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "TradingRecords" */ '../components/TradingRecords/TradingRecords.vue'),

  }
  ,
  {
    path: '/Details',
    name: 'Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Details" */ '../components/TradingRecords/Details.vue')
  }, {
    path: '/agreement',
    name: 'agreement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "agreement" */ '../components/Login/agreement.vue')
  }
  , {
    path: '/FindUser',
    name: 'FindUser',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "FindUser" */ '../components/Ipad/PersonalCenter/FindTenant/FindUser.vue'),
    meta: {
      isAuth: false,
    }
  }
  ,{
    path: '/ServicePhoneIndexDetail',
    name: 'ServicePhoneIndexDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ServicePhoneIndexDetail" */ '../components/Notice/ServicePhoneIndexDetail.vue')

  },
  {
    path: '/BankWithholding',
    name: 'BankWithholding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "BankWithholding" */ '../components/CostManager/PayFee/BankWithholding.vue')
  },
  {
    path: '/ApplyBankAcount',
    name: 'ApplyBankAcount',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ApplyBankAcount" */ '../components/CostManager/PayFee/ApplyBankAcount.vue')
  },
  {
    path: '/ApplyBankAcountDetail',
    name: 'ApplyBankAcountDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ApplyBankAcountDetail" */ '../components/CostManager/PayFee/ApplyBankAcountDetail.vue')
  },
  {
    path: '/BankAccountAgree',
    name: 'BankAccountAgree',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "BankAccountAgree" */ '../components/CostManager/PayFee/BankAccountAgree.vue')
  },
  {
    path: '/BqDetails/:id',
    name: 'BqDetails',
    component: () => import(/* webpackChunkName: "BqDetails" */ '../components/CostManager/BillQuery/BqDetails.vue')
  }
  , {
    path: '/PayDetails/:id',
    name: 'PayDetails',
    component: () => import(/* webpackChunkName: "PayDetails" */ '../components/CostManager/BillQuery/PayDetails.vue')
  }
  , {
    path: '/InformaSign',
    name: 'InformaSign',
    component: () => import(/* webpackChunkName: "InformaSign" */ '../components/Home/InformaSign.vue')
  },
  {
    path: '/meterRead',
    name: 'MeterRead',
    component: () => import(/* webpackChunkName: "MeterRead" */ '../views/MeterRead.vue')
  },
  {
    path: '/waterList/:id',
    name: 'WaterList',
    component: () => import(/* webpackChunkName: "WaterList" */ '../views/WaterList.vue')
  },
  {
    path: '/authApply',
    name: 'AuthApply',
    component: () => import(/* webpackChunkName: "WaterList" */ '../views/AuthApply.vue')
  },
  {
    path: '/maintainceInfo',
    name: 'MaintainceInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "maintainceInfo" */ '../components/MaintainceInfo/MaintainceInfo.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
