import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'; //路由权限
import "./Vantul"
import "lib-flexible"
import TopHome from './components/WaterMeter/top-home.vue';
Vue.config.productionTip = false
Vue.component('TopHome', TopHome);
Vue.config.ignoredElements = ['wx-open-launch-weapp'];

new Vue({
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  render: h => h(App)
}).$mount('#app')
