import Vue from 'vue'

import {
  Button, Icon, Cell, CellGroup, Tab,
  Tabs, Picker, Popup, NoticeBar, Form,
  Field, RadioGroup, Radio, Col, Row, Search,
  IndexBar, IndexAnchor, Checkbox, Collapse,
  CollapseItem, Dialog, Divider, Toast, List,
  DatetimePicker,
} from 'vant';
let arr = [
  Button, Icon, Cell, CellGroup, Tab,
  Tabs, Picker, Popup, NoticeBar, Form,
  Field, RadioGroup, Radio, Col, Row, Search,
  IndexBar, IndexAnchor, Checkbox, Collapse,
  CollapseItem, Dialog, Divider, Toast, List,
  DatetimePicker
]
arr.forEach(item => {
  Vue.use(item);
})


