import request from './request'
import { config } from '@/config'


export const getAppID = (params) => {
  //获取appid
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/wxopen/getOpenId`,
    method: 'get',
    params,
    meta: {
      isToken: false
    }
  })
}
export const getWxLogin = (params) => {
  //微信登录获取微信唯一id
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/wxopen/wxLogin`,
    method: 'get',
    params,
    meta: {
      isToken: false
    }
  })
}
export const getPayLogApi = (params) => {
  //获取支付记录
  return request({
    url: `/api/${config.APIREQUESTHEAD}/zhdt/wx/bill/get/trading`,
    method: 'get',
    params,
  })
}

export const getBillListApi = (params) => {
  //获取账单列表
  //htlx 合同   zdlx账单
  return request({
    url: `/api/${config.APIREQUESTHEAD}/zhdt/wx/bill/get/cost`,
    method: 'get',
    params,
  })
}

export const getBillDetailsApi = (params) => {
  //获取账单详情
  return request({
    url: `/api/${config.APIREQUESTHEAD}/zhdt/wx/bill/get/costInfo`,
    method: 'get',
    params,
  })
}

export const getPayListApi = (params) => {
  //获取缴费列表
  return request({
    url: `/api/${config.APIREQUESTHEAD}/zhdt/wx/bill/get/jnfy`,
    method: 'get',
    params,
  })
}
export const getPayDetailsApi = (params) => {
  //获取缴费详情
  return request({
    url: `/api/${config.APIREQUESTHEAD}/zhdt/wx/bill/get/jfinfo`,
    method: 'get',
    params,
  })
}
export const getPayDataApi = (params) => {
  //获取缴费数据
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/water/wx/get/jnfy`,
    method: 'get',
    params,
  })
}

export const getHistoryPayDataApi = (params) => {
  //获取历史欠费缴费数据
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/water/wx/get/lsqf`,
    method: 'get',
    params,
  })
}

export const getWXConfigInitApi = (params) => {
  //获取微信权限配置
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/get/jsapiTicket`,
    method: 'post',
    params,
  })
}

export const getOnlyCodeApi = (params) => {
  //获取唯一参数
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/wxopen/getUUID`,
    method: 'get',
    params,
  })
}

export const surplusPayApi = (params) => {
  //结余支付
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/water/wx/jypay`,
    method: 'post',
    params,
  })
}

export const getPayResultApi = (params) => {
  //获取支付结果
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/wxopen/getPayResult`,
    method: 'get',
    params,
  })
}


export const getWxPayDataApi = (data) => {
  //获取微信支付数据
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/pay`,
    method: 'post',
    data,
  })
}

export const getHistoryWxPayDataApi = (params) => {
  //获取历史欠费微信支付数据
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/water/wx/payLsqf`,
    method: 'post',
    params,
  })
}

export const getOpenBankDataApi = (params) => {
  //获取开放银行数据
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/water/jkgl/getZhDataByYhId`,
    method: 'GET',
    params,
  })
}

export const getWxGetZh = (name) => {
  // 获取租户数据
  return request({
    url: `/api/${config.APIREQUESTHEAD}/zhdt/open/sys/getAllTenant?tenantName=${name}`,
    method: 'get',
    params: {},
    meta: {
      isToken: false
    }
  })
}

export const getWxAppId = (params) => {
  // 获取appid
  return request({
    url: `/api/${config.APIREQUESTHEAD}/zhdt/open/getWxAppId`,
    method: 'get',
    params,
  })
}

export const getFlowApplyListApi = (code, params) => {
  // 获取申请记录
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/water/wx/flow/${code}`,
    method: 'post',
    params,
  })
}

export const getAnnouncListApi = (params) => {
  // 获取公告列表
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/get/noticeList`,
    method: 'get',
    params,
  })
}

export const getMessageListApi = (params) => {
  // 获取消息列表
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/get/messagePush`,
    method: 'get',
    params,
  })
}

export const getAnnouncDetailsApi = (params) => {
  // 获取公告详情
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/get/noticeinfo`,
    method: 'get',
    params,
  })
}

export const getMessageDetailsApi = (params) => {
  // 获取消息详情
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/get/messagePushInfo`,
    method: 'get',
    params,
  })
}

export const getFlowDataListApi = (params) => {
  // 获取流程列表 或 详情
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/flowList`,
    method: 'post',
    params,
  })
}


export const getUnbindWxUser = (params) => {
  // 解除绑定
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/unbindWxUser`,
    method: 'POST',
    params,
  })
}

export const getWxBindWxUser = (params) => {
  //绑定用户
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/wxopen/bindWxUser`,
    method: 'POST',
    params,
    meta: {
      isToken: false
    }
  })
}

export const getWxBindUserList = (params) => {
  //获取绑定用户列表
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/wxopen/getBindUserList`,
    method: 'GET',
    params
  })
}

export const getWxUserList = (params) => {
  //查询用户
  return request({
    url: `/api/${config.APIREQUESTHEAD}/zhdt/open/getJfyhList`,
    method: 'GET',
    params,
    meta: {
      isToken: false
    }
  })
}


export const getOauthToken = (params) => {
  //获取登录token
  return request({
    url: `/api/blade-auth/oauth/token`,
    method: 'POST',
    params,
    meta: {
      isToken: false
    }
  })
}


export const getComputerTableApi = (id, params) => {
  //获取电脑的表格数据
  return request({
    url: `/api/${config.APIREQUESTHEAD}/cgform-api/getData/${id}`,
    method: 'GET',
    params
  })
}

export const editComputerTableApi = (headId, data) => {
  //修改电脑的表格数据
  return request({
    url: `/api/${config.APIREQUESTHEAD}/cgform-api/editData/${headId}`,
    method: 'post',
    data
  })
}

export const auditAuthApplyApi = (params) => {
  //审核索要权限申请
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/water/userinfo/applyRole/handle`,
    method: 'post',
    params
  })
}

export const getPayTypeIsOpentApi = (params) => {
  //查询开启的支付方式
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/wx/pay/kg`,
    method: 'get',
    params
  })
}


export const openInvoiceApi = (data) => {
  //开发票
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/pt/zckp`,
    method: 'post',
    data
  })
}

export const getInvoiceApi = (params) => {
  //查看发票
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/pt/fplj`,
    method: 'get',
    params
  })
}

export const getInvoiceDetailsApi = (params) => {
  //获取发票详情
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/pt/fpInfo`,
    method: 'get',
    params
  })
}


export const getAllInvoiceApi = (params) => {
  //查看全部发票
  return request({
    url: `/api/${config.APIREQUESTHEAD}/mjkj/zhdt/pt/fplist`,
    method: 'get',
    params
  })
}

export const getAllCostApi = (params) => {
  //查看全部缴费
  return request({
    url: `/api/${config.APIREQUESTHEAD}/zhdt/wx/bill/get/allCost`,
    method: 'get',
    params
  })
}

export const getReceiptApi = (params) => {
  //查看收据
  return request({
    url: `/api/${config.APIREQUESTHEAD}/zhdt/wx/bill/get/receipt`,
    method: 'get',
    params
  })
}

export const getDicTableData = (dictCode) => {
  //获取字典数据
  return request({
    url: `/api/${config.APIREQUESTHEAD}/sys/sys/dict/getDictItems/${dictCode}`,
    method: 'get',
    params: {}
  })
}