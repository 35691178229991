<template>
  <div class="contents">
    <div class="title_Pic Integral">
      <div class="Integral-one">
        <div class="Integral-top-oimg"></div>
        <div>
          <span>您好,{{wxLoginInfo.nickname}}</span>
          <img src="../assets/home/set.png" alt width="22px" @click="jumpPindex('/index')" />
        </div>
      </div>
      <div class="bind-user-info-box" @click="showPopup">
        <div class="info-icon">
          <van-icon name="manager-o" />
        </div>
        <div class="info-box">
          <div class="info-name">{{currUserData.name}}</div>
          <div
            class="info-yyt"
            v-if="currUserData.adminFlag!==1 && currUserData.administratorFlag!==1"
          >{{currUserData.yyt_name | yytFilter}}</div>
          <div class="info-yyt" v-if="currUserData.adminFlag===1">{{currUserData.tenant_name}}（管理员）</div>
          <div
            class="info-yyt"
            v-if="currUserData.administratorFlag===1"
          >{{currUserData.tenant_name}}（超级管理员）</div>
        </div>
        <div class="info-arrow">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
    <div class="menu-list">
      <div class="list-big-item-box">
        <div class="list-box" v-if="currUserData.administratorFlag!==1">
          <div class="list-title">
            <span class="title-hen"></span> 抄表
          </div>
          <div class="list-item-box">
            <div class="list-item" @click="jumpPindex('/meterRead')">
              <div class="item-icon">
                <van-icon name="records" />
              </div>
              <div class="item-text">手动抄表</div>
            </div>
          </div>
        </div>
        <div
          class="list-box"
          v-if="currUserData.administratorFlag===1 || currUserData.adminFlag===1"
        >
          <div class="list-title">
            <span class="title-hen"></span> 业务处理
          </div>
          <div class="list-item-box">
            <div class="list-item" @click="jumpPindex('/authApply')">
              <div class="item-icon">
                <van-icon name="manager-o" />
              </div>
              <div class="item-text">权限申请</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {} from '@/reuqest/api.js'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  filters: {
    yytFilter(value) {
      if (value) {
        return value.split(',').join(' | ')
      }
      return value
    },
  },
  computed: {
    ...mapGetters(['wxLoginInfo', 'bindList']),
    currUserData() {
      let data = {}
      this.bindList.forEach((item) => {
        if (item.flag) {
          data = item
        }
      })
      return data
    },
  },
  mounted() {
    this.$store.dispatch('SystemLogin').then(async () => {})
  },
  methods: {
    showPopup() {
      this.$router.push({ path: '/BandingAccount' })
    },
    jumpPindex(path) {
      this.$router.push(path)
    },
  },
}
</script>
 
<style lang="less" scoped>
.title-dd {
  margin: 0px 10px 20px 10px;
  border-top: 1px solid rgb(241, 241, 241);
}
.contents .Integral {
  height: 110px;
}
.conInfo .UserInfo {
  box-shadow: 0px 1px 5px #9ec6fc;
  height: 100%;
  span {
    font-size: 18px;
  }
}
.title_Pic {
  background: url('../assets/home/topnewbg.jpg') no-repeat;
  background-size: 100% 100%;
  height: 100px;
  .Integral-one {
    display: flex;
    justify-content: space-between;

    align-items: center;

    padding: 18px;
    span {
      color: #fff;
      margin-right: 10px;
      vertical-align: middle;
    }
    img {
      vertical-align: middle;
    }
  }
}
.Index_UserInfo {
  width: 94%;
  background: #fff;
  margin: 0 auto;
  margin-top: -110px;
  border-radius: 15px;
  position: relative;
  z-index: 1;

  .title {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 15px 10px;
  }

  .tb {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 18px;
    .user-info-box {
      font-size: 18px;
      flex: 1;
      text-align: left;
      padding-left: 10px;
      color: #333;
      div:nth-child(2) {
        padding-top: 4px;
      }
    }
  }
}
.van-button--normal {
  padding: 0 40%;
  font-size: 10px;
}
.van-button--info {
  color: #fff;
  background-color: rgb(50, 153, 240);
  border: 1px solid rgb(50, 153, 240);
}
.menu-list {
  margin-top: 20px;
  padding: 10px 0;
  .list-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px solid #f1f1f1;
    text-align: left;
    padding: 8px 20px;
    .title-hen {
      display: inline-block;
      width: 2px;
      height: 14px;
      background-color: #008dff;
      margin-right: 10px;
    }
  }
  .list-item-box {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    border-bottom: 1px solid #f1f1f1;
    .list-item {
      padding: 20px 0;
      border-right: 1px solid #f1f1f1;
    }
    .item-icon {
      font-size: 32px;
      color: #008dff;
    }
    .item-text {
      font-size: 14px;
    }
  }
}
.bind-user-info-box {
  width: 80vw;
  position: absolute;
  left: calc(10vw - 20px);
  top: 55px;
  background-color: #fff;
  box-shadow: 0px 1px 5px #9ec6fc;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  .info-icon {
    font-size: 32px;
    color: #008dff;
    border-right: 1px solid #f1f1f1;
    padding-right: 10px;
  }
  .info-box {
    flex: 1;
    text-align: left;
    padding-left: 10px;
    .info-name {
      font-size: 16px;
    }
    .info-yyt {
      color: #999;
      padding-top: 4px;
    }
    .info-arrow {
      font-size: 18px;
    }
  }
}
</style>