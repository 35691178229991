import router from './router'
import store from './store'
import { getQueryString } from './util/util';
import config from '@/config'
router.beforeEach((to, from, next) => {
  const meta = to.meta || {};
  if (getQueryString('tenantId')) {
    if (to.path === '/') {
      next()
    } else {
      next('/')
    }
  } else if (store.state.wxLoginInfo.openId && store.state.isWxLogin === 'true') {
    //判断是否有opentId
    //已经微信授权过了 不需要授权跳转到首页
    if (to.path === '/') {
      // if (store.state.userType != config.CBY_TYPE_NAME) {
        next({ path: '/home' })
      // } else {
      //   next({ path: '/cbhome' })
      // }
    } else {
      //判断是否有yhtjWxId
      // if (store.state.wxLoginInfo.yhtjWxId || store.state.wxLoginInfo.bladeUserWxId || to.path === '/BusTabs/yhbz') {
      if (store.state.wxLoginInfo.jfyhWxId || to.path === '/BusTabs/yhbz') {
        next()
      } else {
        if (meta.isAuth === false) {
          next()
        } else {
          next('/BandingAccount')
        }
      }
    }
  } else {
    if (meta.isAuth === false && (to.path === '/' || to.path.indexOf('/zfbPay') == 0)) {
      next()
    } else {
      next('/')
    }
  }
})