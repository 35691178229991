import Vue from 'vue'
import Vuex from 'vuex'
import { setToken, setRefreshToken, getToken } from '@/util/auth'
import { getQueryString } from '@/util/util'
import { setStore, getStore } from '@/util/store'
import { getWxLogin, getOauthToken, getWxBindUserList, getAppID } from '@/reuqest/api.js'
import config from '@/config'
import { Toast } from 'vant'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isWxLogin: window.sessionStorage.getItem('isWxLogin') || 'false',
    // appId: getStore({ name: 'appId' }) || 'wx497009194e23b9ee', //本地
    appId: getStore({ name: 'appId' }) || '', //线上
    wxLoginInfo: getStore({ name: 'wxLoginInfo' }) || {},
    tenantId: getStore({ name: 'tenantId' }) || '',
    userInfo: getStore({ name: 'userInfo' }) || {},
    token: getStore({ name: 'token' }) || '',
    refreshToken: getStore({ name: 'refreshToken' }) || '',
    bindList: getStore({ name: 'bindList' }) || [],
    isCut: false,
    // userType: window.sessionStorage.getItem('userType') || '', //当前用户类型
  },
  getters: {
    appId: state => state.appId,
    isWxLogin: state => state.isWxLogin,
    wxLoginInfo: state => state.wxLoginInfo,
    userInfo: state => state.userInfo,
    tenantId: state => state.tenantId,
    token: state => state.token,
    isCut: state => state.isCut,
    bindList: state => state.bindList,
    // userType: state => state.userType,
  },
  mutations: {
    SET_IS_WX_LOGIN: (state, isWxLogin) => {
      state.isWxLogin = isWxLogin
      window.sessionStorage.setItem('isWxLogin', `${isWxLogin}`)
    },
    SET_APP_ID: (state, appId) => {
      state.appId = appId
      setStore({ name: 'appId', content: appId })
    },
    SET_WX_LOGIN_INFO: (state, wxLoginInfo) => {
      state.wxLoginInfo = wxLoginInfo
      setStore({ name: 'wxLoginInfo', content: wxLoginInfo })
    },
    SET_TOKEN: (state, token) => {
      setToken(token)
      state.token = token
      setStore({ name: 'token', content: state.token })
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      setRefreshToken(refreshToken)
      state.refreshToken = refreshToken
      setStore({ name: 'refreshToken', content: state.refreshToken })
    },
    SET_TENANT_ID: (state, tenantId) => {
      state.tenantId = tenantId
      setStore({ name: 'tenantId', content: state.tenantId })
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      setStore({ name: 'userInfo', content: state.userInfo })
    },
    SET_IS_CUT: (state, isCut) => {
      state.isCut = isCut
    },
    SET_BIND_LIST: (state, bindList) => {
      state.bindList = bindList
      setStore({ name: 'bindList', content: state.bindList })
    },
    // SET_USER_TYPE: (state, userType) => {
    //   state.userType = userType;
    //   window.sessionStorage.setItem('userType', `${userType}`)
    // },
  },
  actions: {
    AppIdFun ({ state, commit }) {
      return new Promise(async (resolve, reject) => {
        // let tenantId = getQueryString('tenantId')
        // if (tenantId) {
        //   commit('SET_TENANT_ID', tenantId)
        // }
        // let userType = getQueryString('userType')
        // if (userType) {
        //   commit('SET_USER_TYPE', userType)
        //   commit('SET_IS_WX_LOGIN', 'false');
        // }
        // if (tenantId) {
        getAppID({}).then(res => {
          const data = res.data
          commit('SET_APP_ID', data)
          setTimeout(() => {
            resolve()
          }, 300)
        })
        // } else {
        if (!state.appId) {
          reject()
          return false
        }
        resolve()
        // }
      })
    },
    WxLoginFun ({ state, commit }) {
      return new Promise(async (resolve, reject) => {
        let urls = window.location.href
        let reg = /.*?code=(.*?)&.*?/gis
        let code = ""
        let userState = ""
        if (reg.test(urls)) {
          code = getQueryString('code') //urls.split('?')[1].split('&')[0].split('=')[1]
          userState = getQueryString('state') //urls.split('?')[1].split('&')[1].split('=')[1].split('#')[0]
          let apiData = {
            code,
            appId: state.appId,
            // type: 0,
          }
          // if (state.userType == config.CBY_TYPE_NAME) {
          //   apiData.type = 1
          // }
          if (state.tenantId && state.tenantId == '000000') {
            apiData.tenant_id = state.tenantId
          }
          let res = await getWxLogin(apiData)
          commit('SET_WX_LOGIN_INFO', res.data)
          commit('SET_IS_WX_LOGIN', 'true')
          // let wxId = state.userType != config.CBY_TYPE_NAME ? res.data.yhtjWxId : res.data.bladeUserWxId
          let wxId = res.data.jfyhWxId
          resolve(wxId ? true : false)
        } else {
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${state.appId}&redirect_uri=${config.CALLBACK_URL}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
          reject()
        }
      })
    },
    refreshToken ({ state, commit }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refreshToken, state.tenantId).then(res => {
          const data = res.data
          commit('SET_TOKEN', data.access_token)
          commit('SET_REFRESH_TOKEN', data.refresh_token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    SystemLogin ({ state, commit, dispatch }) {
      //isCut:是否切换登录
      return new Promise(async (resolve, reject) => {
        if (state.token == '' || state.isCut) {
          state.isCut = false
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration: 0,
          })
          let apiData = {
            grant_type: config.GRANT_TYPE,
            scope: config.SCOPE,
            loginType: config.LOGIN_TYPE,
            openId: state.wxLoginInfo.openId,
          }
          // if (state.userType != config.CBY_TYPE_NAME) {
          apiData.jfyhWxId = state.wxLoginInfo.jfyhWxId
          // } else {
          //   apiData.bladeUserWxId = state.wxLoginInfo.bladeUserWxId
          // }
          let data = await getOauthToken(apiData)
          commit('SET_TOKEN', data.access_token)
          commit('SET_REFRESH_TOKEN', data.refresh_token)
          if (data.tenant_id) {
            commit('SET_TENANT_ID', data.tenant_id)
          }
          commit('SET_USER_INFO', data)
          await dispatch('getBindList')
          resolve()
        } else {
          resolve()
        }
      })
    },
    getBindList ({ state, commit }) {
      return new Promise(async (resolve, reject) => {
        let res = {}
        // if (state.userType != config.CBY_TYPE_NAME) {
        //获取绑定的水表用户
        res = await getWxBindUserList({
          openId: state.wxLoginInfo.openId,
        })
        // } else {
        //获取绑定的抄表员
        // res = await getCbyBindListApi({
        //   openId: state.wxLoginInfo.openId,
        // })
        // }
        commit('SET_BIND_LIST', res.data)
        Toast.clear()
        console.log('当前用户绑定的列表', res.data)
        resolve()
      })
    },
    clearLogin ({ state, commit }, bool) {
      return new Promise(async (resolve, reject) => {
        if (!getToken() || state.isWxLogin === 'false' || bool) {
          commit('SET_TOKEN', '')
          commit('SET_REFRESH_TOKEN', '')
          // commit('SET_TENANT_ID', '');
          commit('SET_USER_INFO', {})
          commit('SET_BIND_LIST', [])
          if (bool) {
            commit('SET_WX_LOGIN_INFO', {
              ...state.wxLoginInfo,
              jfyhWxId: '',
              bladeUserWxId: '',
            })
          } else {
            commit('SET_WX_LOGIN_INFO', {})
          }
        }
        resolve()
      })
    }
  },
  modules: {
  }
})
