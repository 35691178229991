<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '@/config'
export default {
  data() {
    return {}
  },
  computed: {
    // ...mapGetters(['userType']),
  },
  mounted() {
    const loadingToast = this.$toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
    })
    this.$store.dispatch('clearLogin').then(() => {
      this.$store
        .dispatch('AppIdFun')
        .then(() => {
          this.$store.dispatch('WxLoginFun').then((res) => {
            loadingToast.clear()
            if (res) {
              // if (this.userType != config.CBY_TYPE_NAME) {
                this.$router.replace({ path: '/home' })
              // } else {
              //   this.$router.replace({ path: '/cbhome' })
              // }
            } else {
              this.$router.replace({ path: '/BandingAccount' })
            }
          })
        })
        .catch((err) => {
          this.$toast('微信配置异常~')
          loadingToast.clear()
        })
    })
  },
}
</script>

<style>
</style>